<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="付款单号" :class="$i18n.locale">
              <el-input v-model="form.paymentCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <el-select v-model="form.status" value-key="id" clearable filterable multiple>
                <el-option
                  v-for="item in _getAllCommodityDict('PAYMENT_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务单号">
              <el-input v-model="form.checkBillCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付款对象" :class="$i18n.locale">
              <el-select v-model="form.vendorId" value-key="id" clearable filterable multiple>
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="付款时间" :class="$i18n.locale">
              <el-date-picker
                v-model="value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="scm_payment" :params="paymentPageParams" />

          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick(0)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" />

    <!-- 付款操作栏弹框 -->
    <el-dialog
      title="付款"
      :visible.sync="payOptionDialogVisible"
      width="400px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form ref="PayForm" :model="PayForm" label-width="130px" :rules="Rules">
        <el-form-item label="付款对象">{{ row.vendorName }}</el-form-item>
        <el-form-item label="业务单号">{{ row.checkBillCode }}</el-form-item>
        <el-form-item label="本次付款时间" prop="paymentTime">
          <el-date-picker
            v-model="PayForm.paymentTime"
            type="date"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="本次付款金额" prop="paymentMoney">
          <el-input
            v-model="PayForm.paymentMoney"
            :placeholder="$t('page.inputPlaceholder')"
            oninput="value=value.replace(/[^\d.]/g,'')"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payOptionDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="disabledFlag" @click="handlePayOption">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :summary-method="getSummaries"
      show-summary
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="400px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentCode" label="付款单号" align="center" min-width="140" sortable />
      <el-table-column prop="status" label="状态" width="90" align="center" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1">待付款</span>
          <span v-else-if="scope.row.status === 2">付款中</span>
          <span v-else-if="scope.row.status === 3">已结款</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkBillCode" label="业务单号" min-width="100" align="center" sortable />
      <el-table-column prop="vendorName" label="付款对象" width="100" align="center" sortable />
      <el-table-column prop="purchaseMoney" label="采购金额" width="100" align="center" sortable />

      <el-table-column prop="checkBillNotPaymentMoney" label="已对账未付款金额" width="160" align="center" sortable />
      <el-table-column prop="checkBillMoney" label="对账金额" width="130" align="center" sortable />
      <el-table-column prop="paymentMoney" label="已付款金额" width="120" align="center" sortable />
      <el-table-column label="操作" fixed="right" width="90" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handlePayOptionVisible(scope.row)">付款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="handlePagination"
      />
    </div>
    <el-row class="pay-records my-2 px-2">
      <el-col :span="12">
        <i class="el-icon-info" style="color: #1890ff" />
        <span class="ml-2 mr-4" style="color: #1890ff">付款记录</span>
        <ImportButton export-key="scm_payment_log" btn-type="primary" :params="exportDetailFormParams" :before-export="handleBeforeExport" />

      </el-col>
    </el-row>
    <!-- 付款记录表格 -->
    <el-table
      ref="multipleDetailTable"
      v-loading="tableDetailLoading"
      class="mb-3"
      :data="tableDetailDatas"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="300px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentCode" label="付款单号" align="center" min-width="140" />
      <el-table-column prop="checkBillCode" label="业务单号" align="center" min-width="140" />
      <el-table-column prop="vendorName" label="付款对象" width="100" align="center" />
      <el-table-column prop="paymentMoney" label="付款金额" width="100" align="center" />
      <el-table-column prop="paymentTime" label="付款时间" width="105" align="center" />
      <el-table-column prop="createTime" label="操作时间" min-width="120" align="center" />
      <el-table-column prop="createByName" label="操作人" min-width="100" align="center" />
    </el-table>
  </div>
</template>

<script>
import { queryVendorList, paymentLogList, paymentPage, payment } from '@/api/scm-api'
import TableSelectAction from '@/components/TableSelectAction'
import Pagination from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import ImportButton from '@/components/ExportFile'
export default {
  components: { TableSelectAction, Pagination, ImportButton },
  mixins: [commodityInfoDict],
  data() {
    return {
      disabledFlag: false,
      pager: {
        total: 0,
        size: 10,
        current: 1
      },
      row: {},
      tableDetailDatas: [],
      tableDetailLoading: false,
      tableDatas: [],
      TableLoading: false,
      payOptionDialogVisible: false,
      multipleSelection: [],
      vendorOptions: [],
      value1: [],
      form: {},
      payForm: {},
      PayForm: {},
      showRow: false,
      rules: {
        vendorId: [{ required: true, message: '请选择付款对象', trigger: 'blur' }],
        paymentMoney: [
          { required: true, message: '金额不能为空' },
          { type: 'number', message: '金额必须为数字值' }
        ]
      },
      Rules: {
        paymentMoney: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        paymentTime: [{ required: true, message: '必填', trigger: 'change' }]
      }
    }
  },
  computed: {
    paymentPageParams() {
      const [startPaymentDate, endPaymentDate] = this.value1 || []
      const { paymentCode, status, checkBillCode, vendorId } = this.form
      return Object.assign({}, { paymentCode, status: status ? status.toString() : '', checkBillCode, vendorId: vendorId ? vendorId.toString() : '' }, this.pager, { startPaymentDate, endPaymentDate })
    },

    exportDetailFormParams() {
      const paymentCode = []
      this.multipleSelection.map(item => paymentCode.push(item.paymentCode))
      return Object.assign({}, { paymentCode: paymentCode?.join() })
    }
  },
  created() { },
  mounted() {
    this._queryVendorList()
    this.queryClick(0)
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 5) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)).toFixed(2)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    close() {
      this.$refs.PayForm.resetFields()
      this.PayForm = {}
    },
    handleBeforeExport() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请至少选中一条数据')
        return false
      }
    },

    handlePagination(val) {
      this.pager.current = val.current
      this.pager.size = val.size
      this.queryClick(1)
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val
      if (val.length > 0) {
        const arr = []
        val.map(item => arr.push(item.paymentCode))
        this._paymentLogList(arr)
      } else {
        this.tableDetailDatas = []
      }
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      this.$refs.multipleTable.clearSelection()
    },
    //   供应商下拉数据
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    resetClick() {
      this.form = {}
      this.value1 = []
      this.queryClick(0)
    },
    async queryClick(type) {
      try {
        type === 0 ? this.pager.current = 1 : ''
        this.TableLoading = true
        const { datas } = await paymentPage(this.paymentPageParams)
        const { records, pager } = datas
        this.tableDatas = records
        // console.log(pager);
        const { current, size, total } = pager
        this.pager = { current, size, total }
        this.TableLoading = false
      } finally {
        this.TableLoading = false
      }
    },
    showRowClick() {
      this.showRow = !this.showRow
    },

    // 操作栏付款按钮
    handlePayOptionVisible(row) {
      this.row = row
      this.payOptionDialogVisible = true
    },
    handlePayOption() {
      this.$refs['PayForm'].validate(async valid => {
        if (valid) {
          try {
            this.disabledFlag = true
            const { id, vendorName } = this.row
            const { id: vendorId } = this.vendorOptions.find(item => item.vendorName === vendorName)
            const obj = Object.assign({}, this.PayForm, { id, vendorId })
            const { code, msg } = await payment(obj)
            code === 0 ? this.$message.success(msg) : this.$message.error('操作失败')
            this.queryClick(0)
            // this._paymentLogList(paymentCode)
            this.payOptionDialogVisible = false
          } finally {
            this.disabledFlag = false
          }
        }
      })
    },
    async _paymentLogList(paymentCode) {
      const { datas } = await paymentLogList({ paymentCode: paymentCode ? paymentCode.toString() : '' })
      this.tableDetailDatas = datas
    }
  }
}
</script>

<style scoped lang="scss">
.pay-records {
  margin-bottom: 20px;
  padding: 3px 0;
  border: 1px solid rgba(186, 231, 255, 1);
  border-radius: 8px;
  background-color: #e6f7ff;
}
</style>
